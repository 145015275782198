import { Card, Collapse, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './RoleDetail.scss';
import '../Role.scss';
import { useHistory } from 'react-router-dom';
import { openNotificationWithIcon } from 'app/components/PopupNotification';
import { createRoleAPI, getRoleDetailAPI, getWebModuleAPI, updateRoleAPI } from 'app/services/api/settingAdmin';
import TextArea from 'antd/lib/input/TextArea';
import useScreenHeight from 'app/hooks/useScreenHeight';
export default function RoleDetail() {
  const { Panel } = Collapse;
  const { Option } = Select;
  const history = useHistory();
  let { id } = useParams<any>();
  const [form] = Form.useForm();
  const [listPermissionModule, setListPermissionModule] = useState([]);
  const screenHeight = useScreenHeight();
  const [heightOfTable, setHeightOfTable] = useState(0);

  useEffect(() => {
    setHeightOfTable(screenHeight - 115);
  }, [screenHeight]);

  useEffect(() => {
    if (Number(id)) {
      getRoleDetail();
    }
    getWebModule();
  }, []);

  const getWebModule = async () => {
    try {
      let res = await getWebModuleAPI();
      setListPermissionModule(res?.data?.data);
    } catch (error: any) {
      openNotificationWithIcon('error', error?.message);
    }
  };

  const getRoleDetail = async () => {
    try {
      let params = {
        id: id,
      };
      let res = await getRoleDetailAPI(params);
      let data = res?.data?.data;
      form.setFieldsValue({
        roleTitle: data?.roleTitle?.trim(),
        roleDescription: data?.roleDescription?.trim(),
      });

      data?.modules?.map(i => {
        form.setFieldValue(
          ['modules', i?.moduleId],
          i?.linkedPermissions?.map(x => x?.id),
        );
      });
    } catch (error: any) {
      openNotificationWithIcon('error', error?.message);
    }
  };

  const handleUpadteRole = async values => {
    let rolePermission = values?.modules ? Object.entries(values?.modules) : null;
    try {
      let params = {
        roleId: Number(id) ? id : 0,
        roleTitle: values?.roleTitle?.trim(),
        roleDescription: values?.roleDescription?.trim(),
        modules: rolePermission?.map((item: any) => {
          return {
            roleModulePermissionId: null,
            moduleId: item[0],
            name: '',
            displayPermissions: [],
            linkedPermissions: item[1]?.map(x => {
              return {
                id: x,
              };
            }),
          };
        }),
      };
      let res;
      if (Number(id)) {
        res = await updateRoleAPI(params);
      } else {
        res = await createRoleAPI(params);
      }
      history.replace('/setting');
      openNotificationWithIcon('success', res?.data?.message);
    } catch (error: any) {
      openNotificationWithIcon('error', error?.message);
    }
  };

  return (
    <Card
      title={Number(id) ? `Edit Role` : 'Add new role'}
      className="card-layout role-detail"
      style={{ width: '99.5%', height: heightOfTable }}
    >
      <div className="role-detail__back" onClick={() => history.goBack()}>
        <img
          src="/icons/back-icon.svg"
          alt="back-icon"
          style={{ height: 20, marginRight: 4 }}
          id="role-detail__button--back"
        />
        Back
      </div>
      <Form layout="vertical" form={form} onFinish={handleUpadteRole}>
        <Form.Item
          name="roleTitle"
          label="Role Name"
          rules={[
            { required: true, whitespace: true, message: 'Role name has not been entered.' },
            { max: 50, message: 'Role Name cannot be longer than 50 characters' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="roleDescription" label="Role Description">
          <TextArea rows={2} style={{ resize: 'none' }} />
        </Form.Item>
        <div className="role-detail__permission-label">Permission</div>
        <div className="role-detail__module-permission">
          {listPermissionModule.map((item: any) => (
            <Collapse defaultActiveKey={item?.moduleId}>
              <Panel
                header={<label htmlFor={`role-detail__panel--${item.name.replace(/\s/g, '')}`}>{item.name}</label>}
                key={item?.moduleId}
              >
                <div role="display">
                  <Form.Item name={['modules', item?.moduleId]}>
                    <Select
                      id={`role-detail__panel--${item.name.replace(/\s/g, '')}`}
                      mode="multiple"
                      maxTagCount={'responsive'}
                      getPopupContainer={trigger => trigger.parentElement}
                      filterOption={(input, option: any) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {item?.displayPermissions.map(x => (
                        <Option value={x?.id}>{x?.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Panel>
            </Collapse>
          ))}
        </div>

        <Form.Item>
          <div className="role-detail__button">
            {/* <button
              type="button"
              className="ant-btn ant-btn-default"
              id="role-detail__cancel"
              style={{ marginRight: 5 }}
              onClick={() => history.replace('/setting')}
            >
              <span>Cancel</span>
            </button> */}
            <button type="submit" className="ant-btn ant-btn-primary" id="role-detail__save">
              <span>Save</span>
            </button>
          </div>
        </Form.Item>
      </Form>
    </Card>
  );
}
