import { createSlice } from '@reduxjs/toolkit';
import { DecisionTableCoverageType, DecisionTableScenario, IDecisionTableInputOutput } from './DecisionTable.type';

export interface DecisionTableState {
  isLoading: boolean;
  isUpdateDecisionTable: boolean;
  isUpdateRACIDecisionTable: boolean;
  isUpdateCoverageDecisionTable: boolean;
  isEditDecisionTable: boolean;
  isEditRACIDecisionTable: boolean;
  isSaveDecisionTable: boolean;
  isShowBlockDecisionTable: boolean;
  isShowBlockRACIDecisionTable: boolean;
  isSaveRACIDecisionTable: boolean;
  activeGroupId: string;
  activeTablesRACIId: string;
  activeTablesCoverageId: number;
  activeTableId: string;
  listTableGroup: IDecisionTableInputOutput[];
  isUpdateDataRACIClient: boolean;
  groupType: string;
  hoverColumn: DecisionTableScenario | undefined;
  dataRootRACI: IDecisionTableInputOutput[];
  dataRACI: IDecisionTableInputOutput[];
  dataRootCoverage: IDecisionTableInputOutput[];
  dataCoverage: DecisionTableCoverageType[];
}

const initialState: DecisionTableState = {
  isLoading: false,
  isUpdateDecisionTable: false,
  isUpdateRACIDecisionTable: false,
  isUpdateCoverageDecisionTable: false,
  isEditDecisionTable: false,
  isEditRACIDecisionTable: false,
  isSaveDecisionTable: false,
  isShowBlockDecisionTable: false,
  isShowBlockRACIDecisionTable: false,
  isSaveRACIDecisionTable: false,
  activeGroupId: '',
  activeTableId: '',
  activeTablesRACIId: '',
  activeTablesCoverageId: 1,
  listTableGroup: [],
  dataRootRACI: [],
  dataRACI: [],
  isUpdateDataRACIClient: false,
  groupType: '',
  hoverColumn: undefined,
  dataRootCoverage: [],
  dataCoverage: [],
};

export const DecisionTableSlice = createSlice({
  name: 'Decision Table',
  initialState,
  reducers: {
    triggerIsUpdateDecisionTable: state => {
      state.isUpdateDecisionTable = !state.isUpdateDecisionTable;
    },
    triggerIsUpdateRACIDecisionTable: state => {
      state.isUpdateRACIDecisionTable = !state.isUpdateRACIDecisionTable;
    },
    isUpdateCoverageDecisionTable: state => {
      state.isUpdateCoverageDecisionTable = !state.isUpdateRACIDecisionTable;
    },
    // triggerIsUpdateDataRACIClient: state => {
    //   state.isUpdateDataRACIClient = !state.isUpdateRACIDecisionTable;
    // },
    triggerIsEditDecisionTable: (state, action) => {
      state.isEditDecisionTable = action.payload;
    },
    triggerIsEditRACIDecisionTable: (state, action) => {
      state.isEditRACIDecisionTable = action.payload;
    },
    triggerIsSaveDecisionTable: (state, action) => {
      state.isSaveDecisionTable = action.payload;
    },
    triggerIsSaveRACIDecisionTable: (state, action) => {
      state.isSaveRACIDecisionTable = action.payload;
    },
    triggerIsShowBlockDecisionTable: (state, action) => {
      state.isShowBlockDecisionTable = action.payload;
    },
    triggerIsShowBlockRACIDecisionTable: (state, action) => {
      state.isShowBlockRACIDecisionTable = action.payload;
    },

    setActiveGroupId: (state, action) => {
      state.activeGroupId = action.payload;
    },
    setActiveTablesRACIId: (state, action) => {
      state.activeTablesRACIId = action.payload;
    },
    setActiveTablesCoverageId: (state, action) => {
      state.activeTablesCoverageId = action.payload;
    },
    setActiveTableId: (state, action) => {
      state.activeTableId = action.payload;
    },
    setListTableGroup: (state, action) => {
      state.listTableGroup = action.payload;
    },
    setListDataRootRACI: (state, action) => {
      state.dataRootRACI = action.payload;
    },
    setListDataRACI: (state, action) => {
      state.dataRACI = action.payload;
      state.isUpdateDataRACIClient = !state.isUpdateRACIDecisionTable;
    },
    setListDataRootCoverage: (state, action) => {
      state.dataRootCoverage = action.payload;
    },
    setListDataCoverage: (state, action) => {
      state.dataCoverage = action.payload;
    },
    setGroupType: (state, action) => {
      state.groupType = action.payload;
    },
    setHoverColumn: (state, action) => {
      state.hoverColumn = action.payload;
    },
  },
  extraReducers: builder => {},
});

export const {
  setActiveGroupId,
  setActiveTablesRACIId,
  setActiveTablesCoverageId,
  triggerIsUpdateDecisionTable,
  triggerIsUpdateRACIDecisionTable,
  isUpdateCoverageDecisionTable,
  triggerIsEditDecisionTable,
  triggerIsEditRACIDecisionTable,
  triggerIsSaveDecisionTable,
  triggerIsShowBlockDecisionTable,
  triggerIsShowBlockRACIDecisionTable,
  triggerIsSaveRACIDecisionTable,
  setActiveTableId,
  setListTableGroup,
  setListDataRootRACI,
  setListDataRACI,
  setGroupType,
  setHoverColumn,
  setListDataRootCoverage,
  setListDataCoverage,
} = DecisionTableSlice.actions;

export default DecisionTableSlice.reducer;
