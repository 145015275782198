import logo from '@src/assets/images/logo.png';
import { Avatar, Button, Popover, Row, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';

import { RootState, useAppSelector } from 'app/store';
import { ActionType, ModuleName } from 'app/models/permission.model';
import { usePermission } from 'app/hooks/usePermission.hook';
import { EXTENSION_ACTION } from 'app/constant';
import { logoutAPI } from 'app/services/api/auth';
import ModalUserDeatil from './Modal/ModalUserDeatil';
import ModalChangePassword from './Modal/ModalChangePassword';
import ModalServiceRequest from 'app/pages/HomePage/Notifications/ModalServiceRequest';

function Header() {
  const user = useAppSelector((state: RootState) => state.auth.user);
  const userDetail = useAppSelector((state: RootState) => state.auth.userDetail);
  const { isHavePermission } = usePermission();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openModalUserDeatil, setOpenModalUserDeatil] = useState(false);
  const [openModalChangePassWord, setOpenModalChangePassWord] = useState(false);
  const [isOpenServiceRequest, setIsOpenServiceRequest] = useState(false);
  const companyID = useAppSelector(state => state?.auth?.user?.companyID);
  const initAdminFunc: any = useMemo(
    () => [
      {
        title: 'Licence Management',
        link: 'licence-management',
        disable: !isHavePermission(ModuleName.AdminFunction, ActionType.VIEW) && !user?.administrator,
      },
      {
        title: 'Roles and Permissions',
        link: 'setting',
        disable: !isHavePermission(ModuleName.AdminFunction, ActionType.VIEW) && !user?.administrator,
      },
      {
        title: 'Service Catalogue',
        link: 'service-catalogue',
        disable: !isHavePermission(ModuleName.ServiceCatalogue, ActionType.VIEW) || companyID > 9,
      },
      {
        title: 'Project Management',
        link: 'project-management',
        disable: !isHavePermission(ModuleName.AdminFunction, ActionType.VIEW),
      },
      {
        title: 'Service Request',
        link: null,
        disable: companyID > 9,
        action: () => {
          if (companyID > 9) return;
          setIsOpenServiceRequest(true);
        },
      },
    ],
    [isHavePermission, user?.administrator],
  );

  const logout = async () => {
    await logoutAPI();
    localStorage.clear();
    sessionStorage.clear();
    window.postMessage({
      action: EXTENSION_ACTION.Logout,
    });

    setTimeout(() => {
      window.location.href = '/login';
    }, 500);
  };

  const _renderAdminFunc = useCallback(() => {
    return initAdminFunc.map(elm => {
      return (
        <div
          className={`header-popup header-popup-item ${elm?.disable ? 'disable-img' : ''}`}
          onClick={() => {
            if (elm?.action) {
              elm?.action();
              setOpen(false);
            } else {
              if (!elm?.disable) {
                setOpen(false);
                history.push(`/${elm?.link}`);
              }
            }
          }}
        >
          <span className={`Clone A Test`}>{elm?.title}</span>
        </div>
      );
    });
  }, [companyID, history, initAdminFunc]);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleCancelModal = () => {
    setIsOpenServiceRequest(false);
  };

  return (
    <>
      <Row className="header">
        <div className="header__logo">
          <Link to="/home" id="header__logo">
            <img src={logo} alt="" />
          </Link>
          {process.env.REACT_APP_ENV_NAME !== 'STAGING' ? (
            <span
              style={{
                fontSize: 35,
                fontWeight: 500,
                paddingLeft: 20,
                color: process.env.REACT_APP_ENV_NAME === 'QA' ? 'black' : 'red',
              }}
            >
              {`${process.env.REACT_APP_ENV_NAME} Environment`}
            </span>
          ) : null}
        </div>
        <div className="header-control">
          <Button id="header__logout__button" className="header__button-logout" onClick={logout}>
            Logout
          </Button>
          <div
            className="btn-sign-in"
            style={{ display: 'flex', alignItems: 'center' }}
            id="profile__button--sign-in"
            role="display"
          >
            <span className="mr-5">
              {userDetail?.firstName} {userDetail?.lastName}
            </span>
            {/* <div style={{ cursor: 'pointer', width: 50 }}>
              <img src="/image/user.png" alt="" onClick={() => setOpenModalUserDeatil(true)} />
            </div> */}
            <Avatar
              size={'large'}
              icon={<UserOutlined />}
              onClick={() => setOpenModalUserDeatil(true)}
              style={{ cursor: 'pointer' }}
            />
          </div>
          {isHavePermission(ModuleName.Information, ActionType.VIEW) && (
            <Tooltip title="Information" className="ml-20 mr-20">
              <NavLink id="information_document-management" to={'/document-management'}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="Frame" clip-path="url(#clip0_8231_36315)">
                    <path
                      id="Vector"
                      d="M3 15C3 16.5759 3.31039 18.1363 3.91345 19.5922C4.5165 21.0481 5.40042 22.371 6.51472 23.4853C7.62902 24.5996 8.95189 25.4835 10.4078 26.0866C11.8637 26.6896 13.4241 27 15 27C16.5759 27 18.1363 26.6896 19.5922 26.0866C21.0481 25.4835 22.371 24.5996 23.4853 23.4853C24.5996 22.371 25.4835 21.0481 26.0866 19.5922C26.6896 18.1363 27 16.5759 27 15C27 11.8174 25.7357 8.76515 23.4853 6.51472C21.2348 4.26428 18.1826 3 15 3C11.8174 3 8.76515 4.26428 6.51472 6.51472C4.26428 8.76515 3 11.8174 3 15Z"
                      stroke="#292D32"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_2"
                      d="M15 11.25H15.0125"
                      stroke="#292D32"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      id="Vector_3"
                      d="M14 15H15V20H16"
                      stroke="#292D32"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_8231_36315">
                      <rect width="30" height="30" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </NavLink>
            </Tooltip>
          )}
          <Tooltip placement="left" title="Admin functions">
            {/* <NavLink id="setting-header" to={'/setting'}> */}
            <div className="popover-header-layout" id="admin-function__button" role="display">
              <Popover
                getPopupContainer={trigger => trigger?.parentNode as any}
                placement="bottom"
                content={_renderAdminFunc()}
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
              >
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 12.8794V11.1194C2 10.0794 2.85 9.21945 3.9 9.21945C5.71 9.21945 6.45 7.93945 5.54 6.36945C5.02 5.46945 5.33 4.29945 6.24 3.77945L7.97 2.78945C8.76 2.31945 9.78 2.59945 10.25 3.38945L10.36 3.57945C11.26 5.14945 12.74 5.14945 13.65 3.57945L13.76 3.38945C14.23 2.59945 15.25 2.31945 16.04 2.78945L17.77 3.77945C18.68 4.29945 18.99 5.46945 18.47 6.36945C17.56 7.93945 18.3 9.21945 20.11 9.21945C21.15 9.21945 22.01 10.0694 22.01 11.1194V12.8794C22.01 13.9194 21.16 14.7794 20.11 14.7794C18.3 14.7794 17.56 16.0594 18.47 17.6294C18.99 18.5394 18.68 19.6994 17.77 20.2194L16.04 21.2094C15.25 21.6794 14.23 21.3994 13.76 20.6094L13.65 20.4194C12.75 18.8494 11.27 18.8494 10.36 20.4194L10.25 20.6094C9.78 21.3994 8.76 21.6794 7.97 21.2094L6.24 20.2194C5.33 19.6994 5.02 18.5294 5.54 17.6294C6.45 16.0594 5.71 14.7794 3.9 14.7794C2.85 14.7794 2 13.9194 2 12.8794Z"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </Popover>
            </div>

            {/* </NavLink> */}
          </Tooltip>
        </div>
      </Row>

      <ModalUserDeatil
        onCancel={() => setOpenModalUserDeatil(false)}
        open={openModalUserDeatil}
        openChangePassword={e => setOpenModalChangePassWord(e)}
      />
      <ModalChangePassword onCancel={() => setOpenModalChangePassWord(false)} open={openModalChangePassWord} />
      <ModalServiceRequest isOpenServiceRequest={isOpenServiceRequest} onCancelModal={() => handleCancelModal()} />
    </>
  );
}

export default Header;
