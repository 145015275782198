import React, { useState } from 'react';
import Paragraph, { ParagraphProps } from 'antd/es/typography/Paragraph';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';

interface Iprops {
  color?: string;
  className?: string;
  width?: string;
  rows: number | undefined;
  placement?: TooltipPlacement | undefined;
  children: React.ReactNode | string;
  disable?: boolean;
  marginTop?: any;
  fontSize?: any;
}

const TooltipMultipleParagraph: React.FC<any> = ({
  disable,
  color,
  rows,
  className,
  width,
  placement,
  children,
  marginTop,
  fontSize,
}: Iprops) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  return (
    <Tooltip placement={placement} title={isTooltipVisible ? children : undefined}>
      <Paragraph
        style={{ width: width, color: color, marginTop: marginTop, fontSize: fontSize }}
        ellipsis={{ rows: rows, onEllipsis: () => setIsTooltipVisible(!isTooltipVisible) }}
        className={className}
      >
        {children}
      </Paragraph>
    </Tooltip>
  );
};

export default TooltipMultipleParagraph;
