export const formatCurrencyNumberWithDecimal = amount => {
  if (amount) {
    var p = amount?.toFixed(2).split('.');
    return (
      '$' +
      p[0]
        .split('')
        .reverse()
        .reduce(function (acc, amount, i, orig) {
          return amount + (amount != '-' && i && !(i % 3) ? ',' : '') + acc;
        }, '') +
      '.' +
      p[1]
    );
  } else return '';
};
export const DEFAULT_STATUS = [
  {
    value: 1,
    label: 'Active',
  },
  {
    value: 2,
    label: 'InActive',
  },
  {
    value: 3,
    label: 'Deleted',
  },
];

export const ValidationSchema = {
  // email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^\s]{8,}$/,
  checkPassSpecial: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
  number: / ^(0|[1-9][0-9]*)$/,
  phoneNumber: /^[0-9+\-\s]{9,15}$/,
  regDigital: /^[0-9]*$/,
  regPhoneNumber: /^\+(?:[0-9]●?){6,14}[0-9]$/,
  positiveNumber: /^[+]?(?!0+)\d*\.?\d+$/,
  onlyWord: /^[a-zA-Z]+(\s[a-zA-Z]+)*$/,
  postcodeLength: /^[0-9a-zA-Z]{3,15}$/,
  dialogReview: /^[0-9a-zA-Z]{50,}$/,
  numberDuration: /^(0?[1-9]|[12][0-9]|3[00])$/,
  checkWhitespace: /\s/,
  ckeckKeyCode: /^[a-zA-Z0-9\+]*$/,
};

export const ValidationError = {
  required: 'This field is required',
  email: 'Email is invalid',
  phoneNumber: 'Contact Number is invalid',
  password: 'Password is invalid',
};

export const RoleTypes = {
  SuperAdmin: 'Super Admin',
  Admin: 'Admin',
};

export const addNoColumn = inputData => {
  return (
    inputData.map((item, idx) => {
      return { ...item, no: Number(idx + 1) };
    }) || []
  );
};

export const filterByStatusCondition = (inputData, filterData, setInputData) => {
  const filteredLicence = inputData.filter(user => {
    return filterData.includes(user.status);
  });
  const data = addNoColumn(filteredLicence);
  setInputData(data);
};

export const defaultRoles = ['Admin', 'Reseller', 'Super Admin'];

export const DefaultStatus = (permission?: boolean) => {
  return [
    {
      label: 'Active',
      value: 1,
    },
    {
      label: 'Inactive',
      value: 2,
    },
    {
      label: 'Deleted',
      value: 0,
      disabled: true,
    },
  ];
};

export const formatCurrencyToNumber = /[^0-9.-]+/g;

export const checkKeyCodeForNumber = (keyCode: number) => {
  if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || keyCode == 8 || keyCode == 49) {
    return true;
  } else {
    return false;
  }
};

export const checkKeyCode = (e: KeyboardEvent) => {
  const isNumberKey = e.key >= '0' && e.key <= '9';
  const isBackspace = e.key === 'Backspace';

  if (!(isNumberKey || isBackspace)) {
    e.preventDefault();
  }
};

export const defaultTableParams = {
  search: '',
  status: [0],
  sort: [
    {
      field: '',
      sortType: 0,
    },
  ],
  page: 1,
  pageSize: 20,
};

export const isVideoOrImageFile = (url?: string) => {
  if (!url) return false;
  const isVideoOrImage = url.match(/\.(jpg|jpeg|png|jfif|gif|mp4|mov|avi|wmv|avchd|webm|flv)$/i);
  return isVideoOrImage;
};

export const isDocxOrExcelFile = (url?: string) => {
  if (!url) return false;
  const isDocxOrExcel = url.match(/\.(docx|xlsx|odp|odt)$/i);
  return isDocxOrExcel;
};

export const iPDFFile = (url?: string) => {
  if (!url) return false;
  const iPDF = url.match(/\.(pdf)$/i);
  return iPDF;
};

export const downloadFile = (url: string, name: string) => {
  const a = document.createElement('a');
  a.target = '_blank';
  a.href = url;
  a.download = name;
  a.click();
  a.remove();
};

export const viewPaperFile = url => {
  const officeViewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(url)}`;
  window.open(officeViewerUrl, '_blank');
};

export enum DashboardStatus {
  DRAFT = 'Draft',
  READY_FOR_REVIEW = 'Ready For Review',
  REWORK_REQUIRED = 'Rework Required',
  APPROVED = 'Approved',
  DEPLOYED = 'Deployed',
  DEPRECATED = 'Deprecated',
  REASSIGN = 'ReAssign',
  STUB = 'Stub',
  CONFIGURING = 'Configuring',
  SCHEDULING = 'Scheduling',
  SCHEDULED = 'Scheduled',
  EXECUTING = 'Executing',
  COMPLETED = 'Completed',
  NEW = 'New',
  OPEN = 'Open',
  DEFERRED = 'Deferred',
  CLOSED = 'Closed',
  RETIRED = 'Retired',
}

export const renderColor = text => {
  switch (text.toLocaleLowerCase()) {
    case DashboardStatus?.DRAFT.toLocaleLowerCase():
      return '#F2B41C';
    case DashboardStatus?.READY_FOR_REVIEW.toLocaleLowerCase():
      return '#54D59A';
    case DashboardStatus?.REWORK_REQUIRED.toLocaleLowerCase():
      return '#6249F8';
    case DashboardStatus.APPROVED.toLocaleLowerCase():
      return '#739EF9';
    case DashboardStatus.DEPLOYED.toLocaleLowerCase():
      return '#F6B168';
    case DashboardStatus.DEPRECATED.toLocaleLowerCase():
      return '#F68268';
    case DashboardStatus.REASSIGN.toLocaleLowerCase():
      return '#9A775C';
    case DashboardStatus.STUB.toLocaleLowerCase():
      return '#F68268';
    case DashboardStatus.CONFIGURING.toLocaleLowerCase():
      return '#F6B168';
    case DashboardStatus.SCHEDULING.toLocaleLowerCase():
      return '#CA5252';
    case DashboardStatus.SCHEDULED.toLocaleLowerCase():
      return '#739EF9';
    case DashboardStatus.EXECUTING.toLocaleLowerCase():
      return '#54D59A';
    case DashboardStatus.COMPLETED.toLocaleLowerCase():
      return '#6249F8';
    case DashboardStatus.NEW.toLocaleLowerCase():
      return '#F6B168';
    case DashboardStatus.OPEN.toLocaleLowerCase():
      return '#739EF9';
    case DashboardStatus.DEFERRED.toLocaleLowerCase():
      return '#54D59A';
    case DashboardStatus.CLOSED.toLocaleLowerCase():
      return '#6249F8';
    case DashboardStatus.RETIRED.toLocaleLowerCase():
      return '#9A775C';
  }
};
